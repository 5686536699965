import {useState} from 'react'
const LoginForm = ({onSubmitForm}) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')


    const onSubmit = (e) => {

        // TODO Validate Data

        e.preventDefault()
        onSubmitForm({username, password})
        setUsername('');
        setPassword('');
    }
    return (

        <form className='login-form ' onSubmit={onSubmit}>
            <div className='row'>
            <div className="three columns">&nbsp;</div>
            <div className="six columns">
                <h5>Trisor Login</h5>
                <div className='form-control'>

                    <input className='u-full-width' type="email" placeholder='E-Mail' value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>

                <div className='form-control'>

                    <input className='u-full-width' type="password" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <input type="submit" value="Login" className="btn u-full-width btn-sm" />
            </div>
            <div className="three columns">&nbsp;</div>
            </div>
        </form>
    )
}

export default LoginForm