const Header = ({logOut, isLoggedIn}) => {
    return (
        <header className='header'>
            <div className="row">
                <div className="six columns"><img src='/img/logo_355.png' alt='for broker Trisor App' className="nav-brand" /></div>
                <div className="six columns ">{isLoggedIn ? <button className='btn btn-sm u-pull-right' onClick={logOut}>Log Out</button> : ''}</div>
            </div>
        </header>
    )
}

export default Header