const Content  = ({}) => {

    return (
        <div id="main" className="row">
            <div className="twelve column">
                <table id="example" className="display u-max-full-width" >
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Office</th>
                        <th>Age</th>
                        <th>Start date</th>
                        <th>Salary</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    )

}

export default Content